import habitat from 'preact-habitat';

document.addEventListener( 'DOMContentLoaded', () => {
	// load habitat widgets asynchronously after
	// selector is located in document
	const newsPostID = 'news-posts';
	if ( docQ( `[data-widget-id="${newsPostID}"]` ) ) {
		const loadNews = async () => {
			const News = await import( './components/news' );
			let { render : render_news } = habitat( News.default );

			render_news( config( newsPostID, {
				split            : false,
				brandingPosition : 'left',
				thumbSize        : 95,
				brandingSize     : 110,
				feedGap          : 100,
			} ) );
		};

		loadNews().then();
	}

	const oNewsPostID = 'olympics-news';
	if ( docQ( `[data-widget-id="${oNewsPostID}"]` ) ) {
		const loadNews = async () => {
			const News = await import( './components/news' );
			let { render : render_oNews } = habitat( News.default );

			render_oNews( config( oNewsPostID, {
				thumbSize : 250,
			} ) );
		};

		loadNews().then();
	}

	const oMedalsID = 'olympics-medals';
	if ( docQ( `[data-widget-id="${oMedalsID}"]` ) ) {
		const loadMedals = async () => {
			const Medals = await import( './components/medals' );
			let { render : render_oMedals } = habitat( Medals.default );

			render_oMedals( config( oMedalsID, {
				displayCount : 5,
				sortBy       : 'Total',
				title        : 'Olympic Medals Race',
			} ) );
		};

		loadMedals().then();
	}
} );

const docQ = ( selector : string ) => document.querySelector( selector );

const config = ( name : string, props = {} ) => ( {
	selector     : `[data-widget-id="${name}"]`,
	defaultProps : props,
	clean        : true,
} );
